import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Filename } from './Filename';

export const FailedState = ({
  fileName,
  isPartialyCompleted,
  clearFromQueue,
  retryFile,
  continueFileUpload,
  resetFileUpload,
}: {
  fileName: string;
  isPartialyCompleted: boolean;
  clearFromQueue: () => void;
  retryFile: () => void;
  continueFileUpload: () => void;
  resetFileUpload: () => void;
}) => {
  const resetButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="bg-lvl2 py-4 d-flex align-items-center">
      <svg className="px-4 text-danger" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
        <g transform="translate(-418 -296)">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
            d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
            transform="translate(419 297)"
          ></path>
          <g transform="translate(429 310)">
            <path fill="none" d="M0,0H20V20H0Z"></path>
            <circle fill="currentColor" cx="12" cy="19" r="2" transform="translate(-1.667 0.667)" />
            <path fill="currentColor" d="M10 3h4v12h-4z" transform="translate(-1.667 0.667)" />
          </g>
        </g>
      </svg>
      <div className="ps-3 flex-grow-1 text-danger" style={{ maxWidth: 'calc(100% - 180px)' }}>
        <div className="d-flex justify-content-between align-items-center">
          <Filename filename={fileName} />
          <span className="text-end">
            {isPartialyCompleted && (
              <>
                This document has already a content of same mimetype partially uploaded. <br />
                <button className="btn btn-secondary mt-1 mx-4" onClick={continueFileUpload} aria-label="continue">
                  Continue the previous upload
                </button>
                <button
                  className="btn btn-secondary mt-1 mx-4"
                  onClick={resetFileUpload}
                  aria-label="reset"
                  ref={resetButtonRef}
                >
                  Reset the upload
                </button>
                <UncontrolledTooltip placement="top" target={resetButtonRef}>
                  Pick this option if you are uploading a different content than the previous one
                </UncontrolledTooltip>
              </>
            )}
            {!isPartialyCompleted && (
              <>
                Failed{' '}
                <button className="btn btn-lg btn-flat-danger mx-4" onClick={retryFile} aria-label="retry">
                  {' '}
                  <i className="icon">replay</i>
                </button>
              </>
            )}
          </span>
        </div>
      </div>
      <div>
        <button className="btn btn-lg btn-flat-light mx-4" onClick={clearFromQueue} aria-label="clear">
          <i className="icon">close</i>
        </button>
      </div>
    </div>
  );
};
