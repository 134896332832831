import { ChunkConflictProblem, Problem, UploadPart } from '@sgdocs/client';
import { DocumentsFilesWithState, FileWithState, UploadingStateType } from '../SGMDocsUpload';
import React from 'react';

export const MAX_NETWORK_BUDGET = 4;
const KB = 1024;
const CHUNK_MAX_SIZE = 500 * KB;

export type ChunkState = {
  state: UploadingStateType;
  start: number;
  end: number;
  size: number;
  isBeingProcessed?: boolean;
};

export type UploadQueueItem = FileWithState & {
  documentId: string;
  workspaceId: string;
  chunksState: ChunkState[];
  alreadyUploadedChunks?: UploadPart[];
};

type ReceiveNewFileAction = {
  type: 'RECEIVED_NEW_FILES';
  documentsFiles: DocumentsFilesWithState;
  onFilesEnqueued?: () => void;
};

type ChunkUploadCompletedAction = {
  type: 'UPLOAD_CHUNK_COMPLETED';
  uploading: ChunkState & UploadQueueItem;
  index: number;
};

type ChunkUploadBeingProcessedAction = {
  type: 'UPLOAD_CHUNK_BEING_PROCESSED_BY_A_THREAD';
  uploading: ChunkState & UploadQueueItem;
  index: number;
};

type ChunkUploadFailedAction = {
  type: 'UPLOAD_CHUNK_FAILED';
  uploading: ChunkState & UploadQueueItem;
  index: number;
  error: Problem;
};

type ResetFileUploadAction = {
  type: 'RESET_FILE_UPLOAD';
  fileId: string;
};

type ContinueFileUploadAction = {
  type: 'CONTINUE_FILE_UPLOAD';
  fileId: string;
};

type ClearFileAction = {
  type: 'CLEAR_FILE';
  fileId: string;
};

type RetryFileAction = {
  type: 'RETRY_FILE';
  fileId: string;
};
export type UploadQueueAction =
  | ReceiveNewFileAction
  | ChunkUploadCompletedAction
  | ChunkUploadFailedAction
  | ClearFileAction
  | RetryFileAction
  | ResetFileUploadAction
  | ContinueFileUploadAction
  | ChunkUploadBeingProcessedAction;

function evaluateUploadStateOfItems(newQueue: UploadQueueItem[]) {
  const uploadingChunksNumber = newQueue
    .flatMap((file) => file.chunksState)
    .filter((chunkState) => chunkState.state === 'UPLOADING').length;
  const newUploadings: (ChunkState & UploadQueueItem)[] = [];
  if (uploadingChunksNumber < MAX_NETWORK_BUDGET) {
    const availableSlots: number = MAX_NETWORK_BUDGET - uploadingChunksNumber;
    for (let availableSlot = 0; availableSlot < availableSlots; availableSlot++) {
      let slotEmpty = true;

      newQueue = newQueue.map((queuedItem) => {
        const documentId = queuedItem.documentId;
        const mimeType = queuedItem.file.type;
        const fileId = queuedItem.id;
        if (
          newQueue.find(
            (queuedItem) =>
              queuedItem.id !== fileId &&
              queuedItem.documentId === documentId &&
              mimeType === queuedItem.file.type &&
              queuedItem.state === 'UPLOADING'
          )
        ) {
          return queuedItem;
        }
        const queueChunk = queuedItem.chunksState.find((chunkState) => chunkState.state === 'QUEUED');
        if (queueChunk && slotEmpty && queuedItem.state !== 'FAILED') {
          return {
            ...queuedItem,
            state: 'UPLOADING',
            chunksState: queuedItem.chunksState.map((chunkState) => {
              if (chunkState.start === queueChunk.start) {
                slotEmpty = false;

                newUploadings.push({
                  ...chunkState,
                  ...queuedItem,
                  state: 'UPLOADING',
                  chunksState: [],
                });
                return { ...chunkState, state: 'UPLOADING' };
              }
              return chunkState;
            }),
          };
        }
        return queuedItem;
      });
    }
  }
  return { newQueue, newUploadings };
}

export type UploadQueueState = {
  queue: UploadQueueItem[];
  uploadings: ((ChunkState & UploadQueueItem) | undefined)[];
  fileChunkMinLength: number;
};
export const uploadQueueReducer = (state: UploadQueueState, action: UploadQueueAction): UploadQueueState => {
  function mergeUploadings(
    uploadings: ((ChunkState & UploadQueueItem) | undefined)[],
    receiveNewUploadings: (ChunkState & UploadQueueItem)[]
  ) {
    return uploadings.map((uploading) => {
      if (!uploading || uploading.state !== 'UPLOADING') {
        return receiveNewUploadings.shift();
      }
      return uploading;
    });
  }

  switch (action.type) {
    case 'RECEIVED_NEW_FILES':
      const filesToEnqueue = Object.entries(action.documentsFiles).flatMap(([workspaceId, documentIdsFiles]) => {
        return Object.entries(documentIdsFiles).flatMap(([documentId, files]) => {
          return files.map<UploadQueueItem>((file) => {
            if (file.file.size <= state.fileChunkMinLength) {
              const chunksState: ChunkState[] = Array(1);
              chunksState[0] = {
                state: 'QUEUED',
                start: 0,
                end: file.file.size,
                size: file.file.size
              };
              return { ...file, chunksState, documentId, workspaceId };
            } else {
              const numberOfChunks = Math.ceil(file.file.size / CHUNK_MAX_SIZE);
              const chunksState = Array(numberOfChunks)
                .fill(null)
                .map((_, chunkIndex) => {
                  const start = chunkIndex * CHUNK_MAX_SIZE;
                  const end = Math.min(CHUNK_MAX_SIZE * (chunkIndex + 1), file.file.size);
                  const chunkState: ChunkState = {
                    state: 'QUEUED',
                    start,
                    end,
                    size: end - start,
                  };
                  return chunkState;
                });
              return { ...file, chunksState, documentId, workspaceId };
            }
          });
        });
      });

      if (action.onFilesEnqueued) {
        action.onFilesEnqueued();
      }

      const { newQueue: receiveQueue, newUploadings: receiveNewUploadings } = evaluateUploadStateOfItems([
        ...state.queue,
        ...filesToEnqueue,
      ]);
      return {
        ...state,
        queue: receiveQueue,
        uploadings: mergeUploadings(state.uploadings, receiveNewUploadings),
      };
    case 'UPLOAD_CHUNK_COMPLETED':
      const newQueue: UploadQueueItem[] = state.queue.map((queuedItem) => {
        if (queuedItem.id === action.uploading.id) {
          const chunksState = queuedItem.chunksState.map<ChunkState>((chunk) => {
            if (chunk.start === action.uploading.start) {
              return { ...chunk, state: 'UPLOADED', isBeingProcessed: false };
            }
            return chunk;
          });
          const uploadingProgress = computeUploadingProgress(chunksState, queuedItem);
          return {
            ...queuedItem,
            uploadingProgress,
            chunksState,
            state: chunksState.find((chunk) => chunk.state !== 'UPLOADED') ? queuedItem.state : 'UPLOADED',
          };
        }
        return queuedItem;
      });
      const { newQueue: chunkCompletedQueue, newUploadings } = evaluateUploadStateOfItems(newQueue);
      const completeUploadings = [...state.uploadings];
      completeUploadings.splice(action.index, 1, newUploadings[0]);
      return {
        ...state, queue: chunkCompletedQueue, uploadings: completeUploadings,
        fileChunkMinLength: state.fileChunkMinLength,
      };
    case 'UPLOAD_CHUNK_BEING_PROCESSED_BY_A_THREAD': {
      const newQueue: UploadQueueItem[] = state.queue.map((queuedItem) => {
        if (queuedItem.id === action.uploading.id) {
          const chunksState = queuedItem.chunksState.map<ChunkState>((chunk) => {
            if (chunk.start === action.uploading.start) {
              return { ...chunk, isBeingProcessed: true };
            }
            return chunk;
          });
          return {
            ...queuedItem,
            chunksState,
          };
        }
        return queuedItem;
      });
      const { newQueue: chunkCompletedQueue, newUploadings } = evaluateUploadStateOfItems(newQueue);
      const completeUploadings = [...state.uploadings];
      completeUploadings.splice(action.index, 1, newUploadings[0]);
      return {
        ...state, queue: chunkCompletedQueue, uploadings: completeUploadings,
        fileChunkMinLength: state.fileChunkMinLength,
      };
    }
    case 'UPLOAD_CHUNK_FAILED':
      const newQueueWithFailed: UploadQueueItem[] = state.queue.map((queuedItem) => {
        if (queuedItem.id === action.uploading.id) {
          const chunksState = queuedItem.chunksState.map<ChunkState>((chunk) => {
            if (chunk.start === action.uploading.start) {
              return { ...chunk, state: 'FAILED', isBeingProcessed: false };
            }
            return chunk;
          });
          return {
            ...queuedItem,
            chunksState,
            state: 'FAILED',
            alreadyUploadedChunks: (action.error as ChunkConflictProblem).alreadyUploadedContentChunks,
          };
        }
        return queuedItem;
      });
      const { newQueue: chunkFailedQueue, newUploadings: newFailedUploadings } =
        evaluateUploadStateOfItems(newQueueWithFailed);
      const uploadings = [...state.uploadings];
      uploadings.splice(action.index, 1, newFailedUploadings[0]);
      return {
        ...state, queue: chunkFailedQueue, uploadings: uploadings,
        fileChunkMinLength: state.fileChunkMinLength,
      };
    case 'CLEAR_FILE':
      const newUploadingCleared = state.uploadings.map((uploading) => {
        if (uploading?.id === action.fileId) {
          return undefined;
        }
        return uploading;
      });
      const newClearedQueue = state.queue.filter((queueItem) => queueItem.id !== action.fileId);
      const { newQueue: clearedQueue, newUploadings: newClearedUploadings } =
        evaluateUploadStateOfItems(newClearedQueue);
      return {
        ...state,
        queue: clearedQueue,
        uploadings: mergeUploadings(newUploadingCleared, newClearedUploadings),
      };
    case 'RETRY_FILE':
      const newRetriedQueue: UploadQueueItem[] = state.queue.map((queuedItem) => {
        if (queuedItem.id === action.fileId) {
          const chunksState = queuedItem.chunksState.map<ChunkState>((chunk) => {
            if (chunk.state === 'FAILED') {
              return { ...chunk, state: 'QUEUED' };
            }
            return chunk;
          });
          return {
            ...queuedItem,
            chunksState,
            state: 'UPLOADING',
          };
        }
        return queuedItem;
      });
      const { newQueue: chunkRetriedQueue, newUploadings: newRetryUploadings } =
        evaluateUploadStateOfItems(newRetriedQueue);
      return {
        ...state, queue: chunkRetriedQueue, uploadings: mergeUploadings(state.uploadings, newRetryUploadings),
        fileChunkMinLength: state.fileChunkMinLength,
      };
    case 'RESET_FILE_UPLOAD': {
      const newResetedQueue: UploadQueueItem[] = state.queue.map((queuedItem) => {
        if (queuedItem.id === action.fileId) {
          const chunksState = queuedItem.chunksState.map<ChunkState>((chunk) => {
            return { ...chunk, state: 'QUEUED' };
          });
          return {
            ...queuedItem,
            chunksState,
            state: 'QUEUED',
            uploadingProgress: 0,
          };
        }
        return queuedItem;
      });
      const { newQueue: chunkResetedQueue, newUploadings: newResetUploadings } =
        evaluateUploadStateOfItems(newResetedQueue);
      return { ...state, queue: chunkResetedQueue, uploadings: mergeUploadings(state.uploadings, newResetUploadings) };
    }
    case 'CONTINUE_FILE_UPLOAD': {
      const newQueue: UploadQueueItem[] = state.queue.map((queuedItem) => {
        if (queuedItem.id === action.fileId) {
          const uploadedChunks = queuedItem.alreadyUploadedChunks?.map(
            (chunk) => `${chunk.initialByteIndex}-${chunk.lastByteIndex}`
          );
          const chunksState = queuedItem.chunksState.map<ChunkState>((chunk) => {
            if (uploadedChunks?.includes(`${chunk.start}-${chunk.end - 1}`)) {
              return { ...chunk, state: 'UPLOADED' };
            }
            return { ...chunk, state: 'QUEUED' };
          });

          const uploadingProgress = computeUploadingProgress(chunksState, queuedItem);
          return {
            ...queuedItem,
            chunksState,
            uploadingProgress,
            state: 'UPLOADING',
          };
        }
        return queuedItem;
      });
      const { newQueue: mergedQueue, newUploadings } = evaluateUploadStateOfItems(newQueue);
      return { ...state, queue: mergedQueue, uploadings: mergeUploadings(state.uploadings, newUploadings) };
    }
    default:
      throw new Error(`${(action as any).type} not supported`);
  }
};

export const onNewFilesReceived = (
  dispatch: React.Dispatch<UploadQueueAction>,
  files: DocumentsFilesWithState,
  onFilesEnqueued?: () => void
) => {
  dispatch({
    type: 'RECEIVED_NEW_FILES',
    documentsFiles: files,
    onFilesEnqueued,
  });
};

function computeUploadingProgress(chunksState: ChunkState[], queuedItem: UploadQueueItem) {
  return Math.round(
    (chunksState
      .filter((chunk) => chunk.state === 'UPLOADED')
      .map((chunk) => chunk.size)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0) /
      queuedItem.file.size) *
    100
  );
}
