import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export function Filename({ filename }: { filename: string }) {
  const filenameRef = useRef<HTMLSpanElement>(null);
  return (
    <>
      <span className="fw-medium text-wrap text-truncate" ref={filenameRef}>
        {filename}
      </span>
      <UncontrolledTooltip placement="top" target={filenameRef}>
        {filename}
      </UncontrolledTooltip>
    </>
  );
}
