import React, { useCallback, useEffect } from 'react';
import { QueuedState } from './QueuedState';
import { DocumentsFilesWithState, UploadState } from '../SGMDocsUpload';
import { UploadingState } from './UploadingState';
import { FailedState } from './FailedState';
import { UploadedState } from './UploadedState';
import { useUploadQueue } from '../hooks/UseUploadQueue';
import { Content } from '@sgdocs/client';

export const InternalSGMDocsUpload = ({
  sgdocsBaseUrl,
  fileChunkMinLength,
  documentsFiles,
  documentsFilesTracker,
  onResetFileUploadFailed,
  onDocumentsFilesChange,
  onUploadCompleted,
  onUploadFailed,
}: UploadState & {
  sgdocsBaseUrl: string;
  fileChunkMinLength: number,
  onResetFileUploadFailed?: (fileId: string, reason: any) => void;
  onUploadCompleted?: (newContentCreated: Content) => void;
  onUploadFailed?: (failureFile: { documentId: string; workspaceId: string; reason: any; file: File }) => void;
  onDocumentsFilesChange: (documentsFiles: DocumentsFilesWithState) => void;
}) => {
  const onFilesEnqueued = useCallback(() => {
    onDocumentsFilesChange({});
  }, []);

  const {
    uploadQueueState,
    enqueueFilesToUpload,
    clearFileFromQueue,
    retryFile,
    resetFileUpload,
    continueFileUpload,
  } = useUploadQueue(
    sgdocsBaseUrl,
    fileChunkMinLength,
    () =>
      new Promise((resolve) =>
        resolve(window.SGWTWidgetConfiguration.bus.dangerouslyGetCurrentValue('sg-connect.access-token') || '')
      ),
    onResetFileUploadFailed,
    onFilesEnqueued,
    onUploadCompleted,
    onUploadFailed
  );

  useEffect(() => {
    if (documentsFiles) {
      enqueueFilesToUpload(documentsFiles);
    }
  }, [documentsFilesTracker]);

  return (
    <>
      {uploadQueueState.map((fileWithState) => {
        switch (fileWithState.state) {
          case 'QUEUED':
            return (
              <QueuedState
                key={fileWithState.id}
                fileName={fileWithState.file.name}
                clearFromQueue={() => clearFileFromQueue(fileWithState.id)}
              />
            );
          case 'UPLOADING':
            return (
              <UploadingState
                key={fileWithState.id}
                fileName={fileWithState.file.name}
                clearFromQueue={() => clearFileFromQueue(fileWithState.id)}
                uploadingProgress={fileWithState.uploadingProgress || 0}
              />
            );
          case 'FAILED':
            return (
              <FailedState
                key={fileWithState.id}
                fileName={fileWithState.file.name}
                clearFromQueue={() => clearFileFromQueue(fileWithState.id)}
                retryFile={() => retryFile(fileWithState.id)}
                continueFileUpload={() => continueFileUpload(fileWithState.id)}
                resetFileUpload={() => resetFileUpload(fileWithState)}
                isPartialyCompleted={
                  !!fileWithState.alreadyUploadedChunks && fileWithState.alreadyUploadedChunks.length > 0
                }
              />
            );
          case 'UPLOADED':
            return (
              <UploadedState
                key={fileWithState.id}
                fileName={fileWithState.file.name}
                sizeInBytes={fileWithState.file.size}
                clearFromQueue={() => clearFileFromQueue(fileWithState.id)}
              />
            );
        }
      })}
    </>
  );
};
